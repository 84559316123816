import React from "react";
import { useThemeUI } from "@theme-ui/core";
import { useViewportScroll, useTransform } from "framer-motion";
import GridRow from "../../commons/GridRow";
import Heading from "../../commons/Heading";
import Box from "../../commons/Box";
import Sticky from "../../commons/Sticky";
import Image from "../../commons/Image";
import Button from "../../commons/Button";
import RichTextRenderer from "../../commons/RichTextRenderer";
import Card from "./Card";
import { linkResolver } from "../../../utils/links";

export default function FlipCardBlock({
  _rawTitle,
  cards,
  buttons,
  ...otherProps
}) {
  const { theme } = useThemeUI();
  const ref = React.useRef();
  const elInfos = React.useRef();
  const orderedCards = [...cards].reverse();

  const { scrollY } = useViewportScroll();

  const scrollYProgress = useTransform(scrollY, (value) => {
    if (!elInfos.current) return value;

    return (
      (value - elInfos.current.top) /
      (elInfos.current.bb.height - window.innerHeight)
    );
  });

  React.useEffect(() => {
    elInfos.current = {
      bb: ref.current.getBoundingClientRect(),
      top: ref.current.offsetTop,
    };
  }, []);

  return (
    <Box
      ref={ref}
      as="section"
      sx={{
        height: `${100 * (cards.length - 1) * 0.9}vh`,
        pt: [4, 0],
        backgroundColor: "background",
      }}
    >
      <Sticky>
        <GridRow
          className="bg-circle"
          sx={{
            position: "relative",
            gridAutoRows: "min-content",
            overflow: "hidden",
            py: [3, 5],
            height: "100vh",
          }}
        >
          <GridRow.Col
            gridColumn={["start / end", "start / span 5"]}
            sx={{ textAlign: ["center", "start"], pt: "gridGap" }}
          >
            <RichTextRenderer blocks={_rawTitle} />
            {!!buttons.length && (
              <Box sx={{ mt: 4 }}>
                {buttons.map(linkResolver).map((link) => (
                  <Button
                    key={link.to || link.href}
                    variant="gradient"
                    {...link}
                  />
                ))}
              </Box>
            )}
          </GridRow.Col>
          <GridRow.Col
            gridColumn={["start / end", "7 / end"]}
            sx={{
              position: "relative",
              justifySelf: "flex-end",
              width: "100%",
              pt: [0, 5],
              height: ["65vh", "45vw"],
            }}
          >
            {orderedCards.map((card, i) => (
              <Box
                key={card._key}
                sx={{
                  position: "absolute",
                  right: 0,
                  width: ["100%", "auto"],
                }}
              >
                <Card
                  index={cards.length - 1 - i}
                  length={cards.length}
                  scrollYProgress={scrollYProgress}
                >
                  <Box
                    as="section"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "card",
                      boxShadow: "card",
                      p: 4,
                      color: "white",
                      width: ["100%", "35vw"],
                      height: ["60vh", "45vw"],
                      maxWidth: 600,
                      maxHeight: "70vh",
                    }}
                    style={{
                      background: `linear-gradient(${
                        i % 2 ? "210deg" : "390deg"
                      }, ${theme.colors.blue} 0%, ${theme.colors.red} 100%)`,
                    }}
                  >
                    <Heading as="h3" sx={{ color: "white" }}>
                      {card.title}
                    </Heading>
                    <Box
                      sx={{
                        mt: 3,
                        maxWidth: 260,
                      }}
                    >
                      <RichTextRenderer blocks={card._rawDescription} />
                    </Box>
                    {card.image && card.image.asset && (
                      <Box sx={{ mt: "auto", minHeight: 0, flex: 1 }}>
                        <Image
                          alt={card.image.alt}
                          fluid={card.image.asset.fluid}
                          sx={{
                            maxHeight: "100%",
                            borderRadius: card.image.asset.metadata.isOpaque
                              ? "circle"
                              : 0,
                          }}
                          imgStyle={{ objectFit: "contain" }}
                        />
                      </Box>
                    )}
                  </Box>
                </Card>
              </Box>
            ))}
          </GridRow.Col>
        </GridRow>
      </Sticky>
    </Box>
  );
}
