import React from "react";
import { motion, useTransform, useSpring } from "framer-motion";
// import { easeIn } from "@popmotion/easing";

export default function Card({
  index,
  length,
  scrollYProgress,
  ...otherProps
}) {
  const scrollYProgressInput = [0, 0.5, 0.9, 1];
  const rotationZInput = [0, 0, 10, 40];
  const translateXInput = ["0%", "0%", "20%", "100%"];
  const opacityInput = [1, 1, 1, 0];

  const startPoint = (1 / length) * index;
  const endPoint = (1 / length) * (index + 1);

  const currentProgress = useTransform(scrollYProgress, (value) => {
    const progressValue = (value - startPoint) / (endPoint - startPoint);

    if (index === length - 1) {
      return Math.min(progressValue, 0.6);
    }

    return progressValue;
  });

  const rotation = useTransform(
    currentProgress,
    scrollYProgressInput,
    rotationZInput
  );
  const x = useTransform(
    currentProgress,
    scrollYProgressInput,
    translateXInput
  );
  const opacity = useTransform(
    currentProgress,
    scrollYProgressInput,
    opacityInput
  );

  const spring = { stiffness: 200, damping: 100 };

  const animRotation = useSpring(rotation, spring);
  const animX = useSpring(x, spring);
  const animOpacity = useSpring(opacity, spring);

  return (
    <motion.div
      style={{
        y: `${(length - 1 - index) * 2}vw`,
        x: animX,
        rotate: animRotation,
        opacity: animOpacity,
      }}
      {...otherProps}
    />
  );
}
